/* FallingRibbons.css */

  
  .ribbon {
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #065C57;
    transition: transform 3s ease-in-out;
    z-index: 3;
  }
  
  .balloon {
    position: absolute;
    width: 20px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    transition: transform 3s ease-in-out;
  }
  
  .ribbon:before,
  .balloon:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 20px;
    background-color: #065C57;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 3s ease-in-out;
  }
  