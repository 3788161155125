@import url("https://fonts.googleapis.com/css?family=Chivo:300,700|Playfair+Display:700i");
@import url("https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans:400,700");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f2731;
  box-sizing: border-box;
  color: #b8bcbf;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

html {
  background-color: #1f2731;
  color: #b8bcbf;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
* {
  font-family: "Roboto", sans-serif;
}